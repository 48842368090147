import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { IMIBadge } from "../../shared/interfaces/michelin.interface";

@Component({
    selector: "app-badge",
    templateUrl: "./badge.component.html",
    styleUrls: ["./badge.component.scss"]
})
export class BadgeComponent implements OnInit {
    @ViewChild("image", { static: false }) image!: ElementRef;

    @Input() public badge!: IMIBadge;
    @Input() public imagePath: string = "assets/img/badges/";
    @Input() public labelPosition: string = "top";
    @Input() public labelCentered: boolean = false;

    public isMouseOver: boolean = false;

    constructor() {}

    ngOnInit(): void {}

    public showConditionTooltip(): void {
        this.isMouseOver = true;
    }

    public hideConditionTooltip(): void {
        this.isMouseOver = false;
    }

    public setDefaultImage(event: any): void {
        event.target.src = "assets/img/badges/default.svg";
    }
}

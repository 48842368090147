import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    IMIBadge,
    IMIBrandedProduct,
    IMIBrandsConfig,
    IMIDisplayProduct,
    IMIProduct,
    IMITireConditionsConfig,
    IMIVehicleType,
    IMIVehicleTypesConfig
} from "../interfaces/michelin.interface";

@Injectable({
    providedIn: "root"
})
export class ProductsService {
    private products: IMIDisplayProduct[] = [];
    private brandsConfig!: IMIBrandsConfig;
    private productTypesConfig!: IMIVehicleTypesConfig | null;
    private tireConditionsConfig!: IMITireConditionsConfig | null;

    public bestForVehicles: IMIVehicleType[] = [];

    constructor(private http: HttpClient) {}

    /**
     * Get products from config based on given product type and map brand data to product data.
     * @param {string} productTypeId
     * @returns {Promise<IMIBrandedProduct[]>}
     */
    public async getProducts(productTypeId: string): Promise<IMIBrandedProduct[]> {
        const products: IMIProduct[] = await this.getAllProducts();
        const brandsConfig: IMIBrandsConfig = await this.getBrandsConfig();
        const productsByType: IMIProduct[] = products.filter((product) =>
            product.vehicleTypeIds.includes(productTypeId)
        );
        return productsByType.map((product: IMIProduct) => ({ ...product, brand: brandsConfig[product.brandId] }));
    }

    /**
     * Get product from config based on given prodcut type and product id and map brand data to product data.
     * @param {string} productId
     * @returns {Promise<IMIBrandedProduct>}
     */
    public async getProduct(productId: string): Promise<IMIBrandedProduct | null> {
        const products: IMIDisplayProduct[] = await this.getAllProducts();
        const brandsConfig: IMIBrandsConfig = await this.getBrandsConfig();
        const product: IMIDisplayProduct | undefined = products.find(
            (product: IMIDisplayProduct) => product.id === productId
        );

        if (product) {
            return { ...product, brand: brandsConfig[product.brandId] };
        } else {
            console.error(`Unable to find product with id ${productId}`);
            return null;
        }
    }

    /**
     * Get product type object from product type id.
     * @param productTypeId
     * @returns {Promise<IMIVehicleType>}
     */
    public async getProductType(productTypeId: string): Promise<IMIVehicleType> {
        const productTypesConfig: IMIVehicleTypesConfig = await this.getProductTypesConfig();
        const productType: IMIVehicleType | undefined = productTypesConfig[productTypeId];
        if (productType) {
            return productType;
        } else {
            throw new Error(`Unable to find prodcut type with id ${productTypeId}`);
        }
    }

    private async getAllProducts(): Promise<IMIDisplayProduct[]> {
        if (this.products.length > 0) {
            return this.products;
        } else {
            const products = await this.http.get<IMIProduct[]>("assets/configs/products.json").toPromise();
            this.products = await this.addConditionBadgesToProducts(products);
            return this.products;
        }
    }

    private async getBrandsConfig(): Promise<IMIBrandsConfig> {
        if (this.brandsConfig) {
            return this.brandsConfig;
        } else {
            const config = await this.http.get<IMIBrandsConfig>("assets/configs/brands.json").toPromise();
            this.brandsConfig = config;
            return config;
        }
    }

    private async getProductTypesConfig(): Promise<IMIVehicleTypesConfig> {
        if (this.productTypesConfig) {
            return this.productTypesConfig;
        } else {
            const config = await this.http.get<IMIVehicleTypesConfig>("assets/configs/productTypes.json").toPromise();
            this.productTypesConfig = config;
            return config;
        }
    }

    public async getAllProductTypes(): Promise<IMIVehicleType[]> {
        const config = await this.getProductTypesConfig();
        return Object.keys(config).map((key) => ({ id: key, name: config[key].name }));
    }

    private async getTireConditionsConfig(): Promise<IMITireConditionsConfig> {
        if (this.tireConditionsConfig) {
            return this.tireConditionsConfig;
        } else {
            const config = await this.http.get<IMITireConditionsConfig>("assets/configs/conditions.json").toPromise();
            this.tireConditionsConfig = config;
            if (!config) {
                throw new Error("Unable to fetch vehicle types config");
            }
            return config;
        }
    }

    public async addConditionBadgesToProducts(products: IMIProduct[]): Promise<IMIDisplayProduct[]> {
        const conditions = await this.getTireConditionsConfig();

        if (!conditions) {
            throw new Error("Unable to fetch conditions config");
        }

        return products.map((product: IMIProduct) => {
            return {
                ...product,
                badges: product.conditions
                    .map((condition) => {
                        if (condition in conditions) {
                            return conditions[condition];
                        }

                        return null;
                    })
                    .filter((badge) => {
                        return badge !== null;
                    }) as IMIBadge[]
            };
        });
    }
}

import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { IMIBrandedProduct, IMIDisplayProduct, IMIVehicleType } from "../../shared/interfaces/michelin.interface";
import { ProductsService } from "../../shared/services/products.service";

@Component({
    selector: "app-landing",
    templateUrl: "./landing.component.html",
    styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {
    [x: string]: any;
    public product$!: Observable<IMIBrandedProduct | null>;

    public bestForVehicles: IMIVehicleType[] = [];
    public showVideo: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private productsService: ProductsService,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.getProduct();
        this.product$.subscribe(async (product) => {
            if (product) {
                this.bestForVehicles = await this.getBestForVehicleTypes(product);
            }
        });
    }

    public getProduct() {
        this.product$ = this.route.params.pipe(
            switchMap((params) => {
                if (params.productId) {
                    return this.productsService.getProduct(params.productId);
                } else {
                    return of(null);
                }
            }),
            map((product) => {
                if (product) {
                    product.safeDescription = this.sanitizer.bypassSecurityTrustHtml(product.description);
                    if (product.videoFileName) {
                        product.safeVideoSrc = this.sanitizer.bypassSecurityTrustUrl(
                            `${environment.assetsUrl}/tire-wall/videos/${product.videoFileName}`
                        );
                    }
                }
                return product;
            }),
            tap((product) => {
                if (!product) {
                    this.router.navigate(["/404"]);
                }
            })
        );
    }

    public toggleVideo() {
        this.showVideo = !this.showVideo;
    }

    private async getBestForVehicleTypes(product: IMIDisplayProduct): Promise<IMIVehicleType[]> {
        const vehicleTypes = await this.productsService.getAllProductTypes();

        if (!vehicleTypes) {
            throw new Error("Unable to fetch vehicle types config");
        }

        return vehicleTypes.filter((vehicleType) => {
            return product.vehicleTypeIds.includes(vehicleType.id);
        });
    }
}

import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ImageLandingComponent } from "./components/image-landing/image-landing.component";
import { LandingComponent } from "./components/landing/landing.component";
import { BadgeComponent } from './components/badge/badge.component';

@NgModule({
    declarations: [AppComponent, LandingComponent, ImageLandingComponent, BadgeComponent],
    imports: [BrowserModule, AppRoutingModule, HttpClientModule],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}

<ng-container *ngIf="product$ | async as product">
    <div class="c-page {{ product.brand ? product.brand.id : 'michelin' }}">
        <div class="p semibold">
            <div class="p__header">{{ product.name }}</div>
            <div class="body">
                <div class="flex">
                    <div class="flex flex--left">
                        <div class="hero"><img [src]="product.heroImageUrl" alt="" /></div>
                    </div>
                    <div class="flex flex--right">
                        <a *ngIf="product.videoFileName" (click)="toggleVideo()" class="video-button"
                            >Watch Video <img class="icon-arrow" src="assets/img/arrow.png" alt=""
                        /></a>
                        <div class="c-logo">
                            <img class="logo-img" [src]="product.brand.logoUrl" alt="" />
                        </div>
                    </div>
                </div>
                <div id="innerHtml" class="product-description" [innerHTML]="product.safeDescription"></div>
                <div class="flex flex--end">
                    <div class="warranty">
                        <div class="warranty__header">Drive with Confidence</div>
                        <ul class="warranty__list">
                            <li class="warranty__item" *ngFor="let warranty of product.warranty">
                                {{ warranty }}
                            </li>
                        </ul>
                    </div>
                    <div class="triangle"></div>
                    <div class="p__info">
                        <div class="best-for">
                            <div class="small-header">Best For</div>
                            <ul class="best-for__list">
                                <li class="best-for__item" *ngFor="let vehicleType of bestForVehicles">
                                    {{ vehicleType.name }}
                                </li>
                            </ul>
                        </div>
                        <div class="weather-conditions">
                            <div class="small-header">Conditions</div>
                            <div class="c-badges">
                                <app-badge
                                    *ngFor="let badge of product.badges"
                                    [badge]="badge"
                                    labelPosition="bottom"
                                    [labelCentered]="true"
                                ></app-badge>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c-video" *ngIf="showVideo">
        <span class="video__close" (click)="toggleVideo()">✖</span>
        <video [src]="product.safeVideoSrc" autoplay></video>
    </div>
</ng-container>
